@import "../../../../../../lib/uio1/profile/css/util.less";

@font-face {
  font-family: "Source sans pro";
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/source-sans-pro-normal.woff2") format("woff2");
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes color_change {
  from {
    background-color: @color-primary;
  }

  to {
    background-color: @color-primary--light;
  }
}
@keyframes color_change {
  from {
    background-color: @color-primary;
  }

  to {
    background-color: @color-primary--light;
  }
}
@-webkit-keyframes color_change_searchform {
  from {
    background-color: @color-neutral-light;
  }

  to {
    background-color: @color-primary;
  }
}
@keyframes color_change_searchform {
  from {
    background-color: @color-neutral-light;
  }

  to {
    background-color: @color-primary;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

.vrtx-horizontal-menu li ul {
  display: block !important;
}

#hidnav a[href="#bottomnav"] {
  display: none;
}

:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.accordion-wrapper .accordion:focus,
.accordion-wrapper:focus {
  outline: none;
}

body {
  .font-size(17);
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  color: #101010;
  font-family: @font-sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

.vrtx-toc-header,
h1,
h2,
h3,
h4,
h5 {
  /*font-family: @font-sans-serif;*/
  color: #101010;
  font-weight: 500;
}

strong {
  font-weight: 600;
}

p {
  margin-bottom: 30px;

  a,
  a:focus,
  a:hover {
    text-decoration: underline;
  }
}

a {
  color: @color-primary;
}

.introduction-div,
.vrtx-frontpage-box.introduction .vrtx-box-content,
.vrtx-introduction,
.vrtx-introduction-big,
.vrtx-organizational-unit-introduction {
  .font-size(18);
  line-height: 1.5;
  font-family: @font-sans-serif;
}

.not-for-ansatte {
  .header-search-collapsable .header-search-expand {
    background-color: @color-dark;
    color: @color-light;
  }

  #head-wrapper {
    #head {
      height: 85px;

      #header {
        height: 100%;

        .head-logo {
          display: block;
          height: 100%;
          width: 100px;
          overflow: hidden;

          img {
            padding: 16px 0;
            height: 100%;
          }
        }
      }
    }
    background-color: white;

    .header-search {
      display: none;
      top: 22.5px;
      z-index: 10;

      input[type="text"] {
        height: 42px;
        padding-left: 15px;
        padding-bottom: 9px;
        background: @color-neutral-light;
        border-radius: 22px 0 0 22px;
        border-radius: 0;
        .placeholderColor(@color-dark, @color-dark);
        color: @color-neutral--dark;
        margin: 0 -6px 0 0;
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
        animation-duration: 0.5s;
        -webkit-animation-duration: 0.5s;
      }

      button {
        height: 42px;
        font-weight: 700;
        padding-bottom: 9px;
        border-radius: 0 22px 22px 0;
        border-radius: 0;
        background: @color-primary;

        &:focus,
        &:hover {
          background: @color-primary--light;
          animation-name: color_change;
          animation-duration: 0.5s;
        }
      }

      .header-search-expand {
        background: url("../images/search-blue.svg") 12px 13px no-repeat;
        font-family: @font-sans-serif;
        font-weight: 400;
        border-radius: 22px;
        border-radius: 0;
        top: 0;
        background-size: 15px auto;
        padding: 8px 12px 8px 34px;
        color: @color-primary;
        background-color: @color-light;
        border: 2px solid #369add;

        &:focus,
        &:hover {
          background: url("../images/search-white.svg") 12px 13px no-repeat;
          background-size: 15px auto;
          color: @color-light;
          background-color: @color-primary;
          animation-name: color_change_searchform;
          animation-duration: 0.8s;
          border: none;
        }
      }
    }

    .vrtx-frontpage-box-picture img {
      margin-top: -36px;
    }

    .uio-app-name {
      display: none;
    }

    .head-menu {
      display: none;
    }

    .vrtx-dropdown-component .vrtx-dropdown-wrapper ul li a {
      color: @color-dark;
    }
  }
}

body.no-menu {
  #globalnav {
    .vrtx-tab-menu {
      display: none;
    }

    &:after {
      background: #fff;
    }
  }

  #head-wrapper {
    background-color: @color-neutral-light;
  }
}

#globalnav {
  display: none;
  margin-top: 0;
  background: @color-light;
  font-family: @font-menu, sans-serif;
  position: relative;

  ul {
    li {
      width: 33.333%;
      border-right: 5px solid white;

      &.vrtx-active-item a {
        background: @color-primary;
        color: @color-light;
        position: relative;
        position: relative;
        font-weight: bold;
        padding: 8px 23px 10px;

        &:focus,
        &:hover {
          color: @color-light;
          padding: 8px 23px 10px !important;
          background: @color-primary;
          text-decoration: underline;
          text-shadow: none;
        }

        &::after {
          content: "";
          width: 100%;
          height: 5px;
          display: block;
          top: auto;
          bottom: -5px;
          background: @color-primary;
          left: 0;
          right: 0;
          position: absolute;
        }
      }

      a {
        padding: 8px 25px 10px;
        color: @color-primary;
        background: @color-neutral-light;
        font-size: 20px;
        font-size: 2rem;
        position: relative;
      }

      a {
        &:focus,
        &:hover {
          color: @color-primary;
          background: @color-neutral-light;
          font-weight: bold;
          padding: 8px 23px 10px;
        }
      }

      &:first-of-type {
        display: none;
      }

      &:nth-of-type(4) {
        border-right: none;

        &.vrtx-active-item {
          border-right: 5px solid @color-light;

          a {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 47px;
    background: #23619d;
    position: absolute;
  }
}
/* Menu (submenu)  under globalnav */
.vrtx-submenu {
  display: none;
  font-family: @font-menu, sans-serif;
  width: 100%;
  height: 47px;
  background: @color-primary;

  ul.vrtx-breadcrumb-menu {
    width: 960px;
    margin: 0 auto;

    li {
      float: left;
      display: inline-block;
      font-size: 0;
      margin-right: 15px;

      &.vrtx-parent {
        a {
          display: none;
        }

        ul li a {
          display: inline-block;
        }
      }

      a {
        color: @color-light;
        display: inline-block;
        font-size: 16px;
        text-align: center;
        font-size: 1.6rem;
        padding: 10px;
        position: relative;

        &:focus,
        &:hover {
          text-shadow: 0 0 1px white;
          text-decoration: none;
          outline: none;

          &::after {
            content: "";
            width: 100%;
            height: 4px;
            display: block;
            top: auto;
            bottom: -3px;
            background: @color-primary--light;
            left: 0;
            right: 0;
            position: absolute;
          }
        }

        &.vrtx-marked {
          font-weight: bold;
          position: relative;

          &:focus,
          &:hover {
            text-decoration: underline;
            text-shadow: none;
          }

          ::after {
            content: "";
            width: 100%;
            height: 4px;
            display: block;
            top: auto;
            bottom: -3px;
            background: @color-primary--light;
            left: 0;
            right: 0;
            position: absolute;
          }
        }

        ::before {
          display: block;
          content: attr(title);
          font-weight: bold;
          height: 0;
          overflow: hidden;
          visibility: hidden;
        }
      }
    }
    /* hide submenu on about -page*/
    > li:not(.vrtx-parent) a:not(.vrtx-marked) {
      display: none;
    }
  }
}

#main .grid-container {
  margin-bottom: 0;
}

#main #left-main.hidden {
  display: none !important;
}

#left-main {
  display: none;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.2s;
  -webkit-animation-duration: 0.2s;

  &.sticky {
    position: fixed;
    top: 40px;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    animation-duration: 0.2s;
    -webkit-animation-duration: 0.2s;
    opacity: 1;
  }
}

#main .vrtx-back a:before {
  background: url("../images/icon-chevron-back.svg") no-repeat 0 3px transparent;
  background-size: auto 80%;
}
/* Nettverk page */
#left-main.hide {
  display: none !important;
}

#main #right-main.fullwidth {
  width: 100%;

  #vrtx-content {
    width: 100%;

    #vrtx-main-content {
      width: 730px;
    }

    #vrtx-additional-content {
      padding-left: 38px;
      margin-left: 0;
      border-left: 4px solid #f5f7f8;
    }
  }
}

#vrtx-frontpage .vrtx-frontpage-box.heading3-with-image.vrtx-frontpage-box-picture,
.vrtx-frontpage-box.heading2-with-image.vrtx-frontpage-box-picture {
  h3 {
    margin-bottom: 35px;
    padding-right: 0;
    padding-top: 20px;
  }

  h2 {
    margin-bottom: 35px;
    padding-right: 0;
    padding-top: 20px;
  }

  .vrtx-frontpage-box-picture {
    float: right;
    margin: -10px 0 0;
    max-width: 100px;
  }
}

#vrtx-frontpage .vrtx-frontpage-box.white-box {
  background: @color-light;
}

#main #vrtx-content .vrtx-box-content {
  padding-bottom: 40px;
}

.footer > .vrtx-search-scope a::before,
a.all-comments::before,
a.all-messages::before,
a.more-url::before,
a.vrtx-more::before,
div.vrtx-more a::before {
  background: url("../images/arrow-forward.svg") no-repeat 0 10px;
}

table {
  th {
    font-weight: bold;
  }
}

.toc-hide-h3 #toc ul li ul {
  display: none;
}
/* Hide mobile menu on desktop */
.vrtx-frontpage-box.mobile-menu {
  display: none;
}
/* Vrtx toc */
.toc-hide-h3 {
  border: 1px solid #eaeaea;
  width: 190px;
  position: static;

  .vrtx-toc-header {
    width: 100%;
    display: block;
    margin: 0;
    background: @color-primary;
    font-size: 16px;
    font-size: 1.6rem;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 600;
    color: @color-light;
    text-transform: uppercase;
    padding: 0 10px;
  }

  #toc {
    li {
      background: @color-light;
      margin-bottom: 0;
      border-bottom: 1px solid #eaeaea;

      a {
        color: #101010;
        font-family: @font-sans-serif;
        font-weight: 400;
        display: block;
        padding: 10px;
        font-size: 16px;
        font-size: 1.6rem;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}

.not-for-ansatte #bottomnav {
  display: none;
}
/* List styles */
.grid-container ul > li:before,
.uio-main ul > li:before {
  content: "\25A0";
  color: @color-dark;
  margin-left: -2.5ex;
  position: absolute;
  top: 4.4px;
  top: 2px;
  left: 0;
  width: 1em;
  overflow: hidden;
  font-size: 0.8em;
}
/* Border color */
.not-for-ansatte #main {
  .vrtx-paging-feed-wrapper {
    border-top: 6px solid @color-neutral-light;
  }

  .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line) .vrtx-frontpage-box.third-box-right {
    border-left: 2px solid @color-neutral-light;
  }

  .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line) .vrtx-frontpage-box.third-box-left {
    border-right: 2px solid @color-neutral-light;
  }

  .vrtx-authors,
  .vrtx-date-info,
  .vrtx-tags {
    border-top: 6px solid @color-neutral-light;
  }
}
/* Arrows */
.not-for-ansatte {
  #main .vrtx-back a:before {
    background: url("../images/icon-chevron-back-blue.svg") no-repeat 0 3px transparent;
    background-size: auto 80%;
  }
  /* Accordions */
  /* new april 2021 */
  .ui-accordion.ui-widget {
    clear: both;

    .ui-accordion-header {
      padding-top: 10px;
      padding-bottom: 10px;
      border: none;
      border-top-color: currentcolor;
      border-top-style: none;
      border-top-width: medium;
      border-bottom-color: currentcolor;
      border-bottom-style: none;
      border-bottom-width: medium;
      border-bottom: 1px solid #9b9b9b;
      color: var(--textColor);
      background: 0 0;
      margin: 0;
      padding: 15px 60px 15px 0;
      position: relative;
      -webkit-transition: 0.2s padding ease-in-out;
      -moz-transition: 0.2s padding ease-in-out;
      -o-transition: 0.2s padding ease-in-out;
      transition: 0.2s padding ease-in-out;
      clear: left;

      &:not(.ui-accordion-header-active) {
        &:focus {
          color: #0b619d;
        }
      }

      &:first-child {
        border-top: 1px solid #9b9b9b;
      }

      .ui-icon {
        line-height: 0;
        display: block;
        width: 0.6em;
        height: 0.3em;
        position: absolute;
        right: 0;
        top: 50%;
        line-height: 0;
        background: none;

        &::after {
          height: 100%;
          width: 100%;
          content: "+";
          display: block;
          -webkit-transition: 0.2s all ease-in-out;
          -moz-transition: 0.2s all ease-in-out;
          -o-transition: 0.2s all ease-in-out;
          transition: 0.2s all ease-in-out;
        }
      }

      &.ui-accordion-header-active {
        border-bottom: 1px solid transparent;

        .ui-icon::after {
          content: "–";
        }
      }
    }

    p.ui-accordion-header {
      padding: 5px 0;
      height: 25px;
      height: 40px;
      font-family: @font-sans-serif;
      font-weight: 500;
      margin-left: 0;
      cursor: pointer;
    }

    .ui-accordion-content > :last-child {
      margin-bottom: 0;
    }

    &:focus,
    &:hover {
      .ui-accordion-header:not(.ui-accordion-header-active) {
        color: #0b619d;
      }
    }

    .accordion-content {
      color: #101010;
    }
  }

  .ui-accordion.ui-widget .ui-accordion-header {
    &:focus:not(:focus-visible) {
      outline: none;
    }
  }
}
/* facts box */
.not-for-ansatte #main .example {
  margin-bottom: 20px;
  background-color: #f2f2f2;
  padding: 30px;

  .ui-accordion.ui-widget {
    margin: 0;
  }

  h3:not(.accordion) {
    font-style: normal;
    padding: 0;
    margin-top: 0;
    padding-bottom: 10px;
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 20px;
    line-height: 2rem;
  }

  h3.accordion {
    border: none;
  }

  &.toggle-container button.toggle-more {
    background: linear-gradient(rgba(242, 242, 242, 0.13), rgba(242, 242, 242, 0.77), rgba(242, 242, 242, 0.95), #f2f2f2, #f2f2f2, #f2f2f2);
    color: @color-primary;
  }
}
/* Contact form */
@media (max-width: 980px) {}

.nettskjema-iframe {
  margin-left: -20px;
}
/* blockquote */
blockquote {
  border-left: 4px solid @color-primary--light;
  padding-left: 60px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 30px;
  line-height: 3rem;
  font-family: @font-sans-serif;

  &.half-width-left {
    width: 45%;
    float: left;
    margin-right: 5%;
  }

  &.half-width-right {
    width: 45%;
    float: right;
    margin-left: 5%;
  }
}
/* Fix black line around videos */
.vrtx-frontpage-box .vrtx-box-content .vrtx-media-player {
  background-color: transparent;
  position: relative;
  margin-bottom: 0;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    pointer-events: none;
    outline-offset: -3px;
    outline: 5px solid #f5f7f8;
  }
}
/* searchview */
#vrtx-searchview #main {
  padding-top: 40px;
  min-height: 300px;
}
/* Footer */
#footer-wrapper {
  color: @color-light;
  background: @color-primary;
  padding-bottom: 250px;
  padding-top: 0;
  position: relative;
  background: linear-gradient(top, #20a9e0, #0075af);
  background: -moz-linear-gradient(top, #0b619d, #0075af);
  background: -ms-linear-gradient(top, #0b619d, #0075af);
  background: -o-linear-gradient(top, #0b619d, #0075af);
  background: -webkit-linear-gradient(top, #0b619d, #0075af);

  &.grid-container ul > li:before {
    content: "";
  }

  #footers {
    width: 100%;
    background: @color-light !important;
    .font-size(18);
    color: @color-light;

    a,
    h2 {
      color: @color-light;
    }

    .col-3-5.vcard {
      width: 100%;
      margin: 0 auto;
      padding: 15px 0;
      text-align: center;
      float: none;
      background: @color-light;

      .libraries {
        display: flex;
        text-align: center;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;

        a {
          text-align: center;
          padding: 15px 25px;
          height: 100px;

          &:focus,
          &:hover {
            background-color: transparent;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            text-decoration: none;
          }

          &.normwidth img {
            width: 270px;
          }

          &.wnuas img {
            width: 150px;
          }

          &.nb img {
            width: 120px;
          }

          &.xtrapadding {
            padding-left: 25px;
            padding-right: 25px;
          }

          &.nopadding {
            padding: 0;
          }

          img {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      .links {
        position: absolute;
        bottom: -200px;
        margin-left: 160px;
      }
    }

    .col-2-5.responsible-login {
      background: transparent;
      width: 970px;
      position: absolute;
      bottom: -200px;
      margin: 0 auto;
      right: 0;
      left: 0;

      .content {
        float: right;
      }

      a {
        &:focus,
        &:focus-visible,
        &:hover {
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          text-decoration: underline;
          outline: @color-light auto 5px;
        }
      }

      #footer-links {
        float: left;

        a {
          display: block;
          font-size: 20px;
          font-size: 2rem;
          line-height: 29px;
          line-height: 2.9rem;
          padding: 5px 0;
        }
      }
    }

    .vrtx-dropdown-component {
      .font-size(13);
    }
  }

  &.red {
    background: @color-dark none;
  }
}
/* general elements */
.button,
button {
  border-radius: 0;
  font-family: @font-sans-serif;
  font-weight: 500;
}

.button,
.button-large,
.button-large-blue,
.vrtx-button-larger:not(form),
.vrtx-button:not(form),
button,
input[type="password"],
input[type="text"],
input[type="search"],
input[type="button"],
input[type="submit"],
input[type="cancel"] {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

#main form#emner button[type="submit"]:after,
#main form#emner input[type="submit"]:after,
#main form#enheter button[type="submit"]:after,
#main form#enheter input[type="submit"]:after,
#main form#personer button[type="submit"]:after,
#main form#personer input[type="submit"]:after,
#main form.vrtx-big-search button[type="submit"]:after,
#main form.vrtx-big-search input[type="submit"]:after,
#main form.vrtx-search-form button[type="submit"]:after,
#main form.vrtx-search-form input[type="submit"]:after {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0 0 0 0;
  -webkit-border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-bottomleft: 0;
}

#main form#emner button[type="submit"]:before,
#main form#emner input[type="submit"]:before,
#main form#enheter button[type="submit"]:before,
#main form#enheter input[type="submit"]:before,
#main form#personer button[type="submit"]:before,
#main form#personer input[type="submit"]:before,
#main form.vrtx-big-search button[type="submit"]:before,
#main form.vrtx-big-search input[type="submit"]:before,
#main form.vrtx-search-form button[type="submit"]:before,
#main form.vrtx-search-form input[type="submit"]:before {
  border-width: 1px;
}
/* table */
table {
  th {
    background: @color-primary;
    color: @color-light;
    border-width: 1px;
    /*border-bottom-color:  @color-primary;*/
  }

  &.vertical {
    tbody th[scope="row"],
    td,
    th {
      border-width: 1px;
    }
  }
}
/* facts container */
body .vrtx-facts-container {
  background: @color-primary;
  color: @color-light;
  font-family: @font-sans-serif;
  font-weight: 400;
  padding: 25px 30px 30px;

  h2,
  h3,
  h4,
  h5 {
    color: @color-light;
    margin-top: 0;
  }

  a {
    color: @color-light;
    text-decoration: underline;

    &:hover {
      font-weight: bold;
    }
  }

  ul li:before {
    color: #369bdb;
  }

  &.vrtx-container-full-width {
    width: 100%;
    float: none;
    padding: 30px;

    p {
      padding: 0;
      margin: 0;
    }
  }

  &.toggle-container {
    .toggle-more {
      color: #ffffff;
    }
  }
}

.toggle-container {
  max-height: 320px;
  overflow: hidden;
  position: relative;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

  .toggle-more {
    left: 0;
    width: 100%;
    font-weight: 400;
    position: absolute;
    bottom: 0;
    margin: 0;
    font-family: @font-sans-serif;
    cursor: pointer;
    padding: 85px 30px 30px;
    background: linear-gradient(rgba(35, 97, 157, 0.17), rgba(35, 97, 159, 0.92), #0b619d, #0b619d, #0b619d, #0b619d);
    background-color: transparent !important;
    margin: 0 !important;
    text-align: left;

    &:focus,
    &:hover {
      outline: none;
      text-decoration: underline;
    }

    a {
      text-decoration: none;

      &:focus,
      &:hover {
        text-decoration: underline;
        outline: none;
      }

      &::before {
        content: "+";
        padding-right: 10px;
        font-size: 2.44rem;
        font-weight: 300;
        display: inline-block;
      }

      &:focus,
      &:hover {
        font-weight: inherit;
      }
    }

    &:focus a,
    &:hover a {
      text-decoration: underline;
    }
  }

  &.expanded {
    max-height: none;

    .toggle-more {
      background: transparent;
      padding: 40px 0 0;
      position: relative;
      margin-left: 0;
      display: block;
      width: 200%;

      a {
        &::before {
          content: "-";
        }
      }
    }
  }
}
/* remove borders */
.not-for-ansatte #main .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line) .vrtx-frontpage-box.third-box-right {
  border-left: none;
}

.not-for-ansatte #main .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line) .vrtx-frontpage-box.third-box-left {
  border-right: none;
}

#scrolltop {
  position: fixed;
  display: none;
  right: 0;
  width: 50px;
  height: 50px;
  bottom: 20px;
  background: url("../images/icon-chevron-up-blue.svg") no-repeat center center rgba(255, 255, 255, 0.7);
  background-size: 70%;
  margin: 20px 20px 40px;
  border-radius: 50%;
  border: 2px solid @color-primary--light;
  z-index: 100;

  &:focus,
  &:hover {
    color: @color-primary--light;
    box-shadow: 0 0 5px @color-primary--light;
    text-shadow: 0 0 5px @color-primary--light;
    background: url("../images/icon-chevron-up-blue.svg") no-repeat center center rgb(255, 255, 255);
    background-size: 70%;
  }
}
@media print {}

#mobile-menu {
  display: none;
}
@media only screen and (max-width: 16cm) and (orientation: portrait), only screen and (max-width: 19cm) and (orientation: landscape) {
  .js-ready.csstransforms.csstransitions .grid-container:nth-child(2) {
    margin-top: 0;
  }

  .not-for-ansatte {
    #globalnav,
    .vrtx-submenu {
      display: none !important;
    }
  }

  body {
    .font-size(16);
    line-height: 1.5;
  }

  .js-ready #nav-offcanvas {
    background: @color-primary;

    #lang-link {
      display: none;

      a {
        color: @color-primary--dark;
      }
    }

    li {
      border-top: 1px solid @color-secondary;
      background: @color-primary--dark;

      &.is-active > a {
        background-color: @color-primary;
        color: @color-light;
      }

      &.ancestor li {
        background-color: @color-primary--dark;
      }

      a#close-nav {
        color: @color-light;
        background: url("../images/close-icon-white.svg") 30px 1.15em no-repeat;
        background-size: auto 1.5ex;
      }

      &.vrtx-parent li.vrtx-child:before {
        top: 0.9em;
        color: @color-secondary;
      }
    }

    .nav-seperator {
      background-color: @color-secondary;
    }
  }

  .not-for-ansatte {
    #head-wrapper {
      background: @color-neutral-light;

      #head {
        #header {
          .head-logo {
            display: block;
            width: 122px;

            img {
              padding: 15px;
            }
          }
        }

        .header-search {
          display: block !important;
          width: 100%;
          border-radius: 0;
          height: 1px;
          top: 0;
          font-family: @font-sans-serif;
          font-weight: 500;

          .header-search-expand {
            display: block;
            text-indent: -9999em;
            text-transform: uppercase;
            padding: 30px;
            background: url("../images/search-blue.svg") center center no-repeat;
            background-size: 20px auto;
            border: none;
            border-radius: 0;
            top: 0;
            right: 111px;
            height: 85px;
            width: 110px;
            width: auto;
            margin: 0 auto;
            float: right;
            overflow: hidden;
            padding: 8px 10px 6px 34px;
            color: #0b619d;
            background-color: #eff3f691;
            background-color: transparent;

            &:focus,
            &:hover {
              background-color: #eff3f691;
              animation: none;
            }

            &.active {
              background: url("../images/close-icon-white.svg") center center no-repeat;
              background-size: 18px auto;
              color: @color-light;
              display: block;
              background-color: @color-primary;

              &:focus,
              &:hover {
                background-color: @color-primary;
              }
            }
          }

          form {
            display: none;
          }
        }
      }
    }
    /* Show mobile menu on mobile */
    #mobile-menu {
      display: block;
      position: relative;

      #menu-icon {
        height: 85px;
        padding: 0 15px;
        background: url("../images/menu-icon-grey.svg") 15px center no-repeat;
        background: url("../images/menu-icon-blue.svg") right 15px center no-repeat;
        border: none;
        border-radius: 0;
        top: 0;
        font-weight: bold;
        width: auto;
        float: right;
        background-size: 20px auto;
        background-color: @color-neutral-light;
        position: absolute;
        top: -85px;
        right: 0;
        cursor: pointer;

        img {
          display: none;
          float: right;
          width: 30px;
        }

        span {
          padding-right: 28px;
          color: #0b619d;
          font-size: 14px;
          font-size: 1.4rem;
          top: -3px;
          display: block;
        }

        span.close-menu {
          display: none;
          margin-right: 5px;
          padding-left: 5px;
          color: @color-light;

          img {
            width: 20px;
            padding-top: 5px;
          }
        }

        &.active {
          background-color: @color-primary;
          z-index: 100;
          padding: 0 30px;
          background: url("../images/close-icon-white.svg") right 30px center no-repeat @color-primary;
          background-size: 15px;
          border-bottom: none;
          outline: none;

          span.open-menu {
            display: none;
          }

          span.close-menu {
            display: block;
          }
        }

        &:focus,
        &:hover {
          span {
            text-decoration: underline;
          }
        }
      }

      .vrtx-subfolder-menu li {
        background: @color-primary;

        a {
          &:first-of-type {
            margin-top: 0;
          }
        }

        &:last-of-type {
          padding-bottom: 15px;
        }
      }
    }
    /* search */
    #inner-content-outer-wrapper {
      #inner-content-wrapper {
        #search.menu-search {
          display: none;
          width: 100%;
          margin-top: 104px;
          background: @color-primary;

          #search-string-responsive,
          button:before {
            border: none;
          }

          #search-string-responsive,
          button {
            border-radius: 0;
          }

          button {
            width: 40px;
            height: 45px;
            text-indent: -9999em;
            background: url("../images/magnifying-glass-white.png") center center no-repeat;
            background-size: 20px;
            background-color: @color-primary--light;

            &::before {
              content: none;
            }

            &::after {
              content: none;
            }
          }
        }
      }
    }

    .vrtx-frontpage-box.mobile-menu {
      display: none;
      font-family: @font-menu, sans-serif;
      background: @color-primary;
      padding: 0 0 30px;

      ul {
        width: 100%;
        padding-right: 0;
      }

      li a {
        display: block;
        padding: 7px 0;
      }

      ul li {
        font-weight: bold;
        text-transform: uppercase;

        &:first-of-type a {
          border-top: none;
        }

        a {
          font-size: 18px;
          background: @color-primary;
          padding-left: 15px;
          color: @color-light;
          margin-top: 15px;
          border-top: 1px solid #369cdb;
          padding-top: 15px;
        }

        ul li {
          position: relative;
          font-weight: normal;
          text-transform: none;
          padding-left: 15px;

          a {
            padding-left: 15px;
            width: 100%;
            margin-top: 0;
            display: block;
            font-size: 16px;
            font-size: 1.6rem;
            background: @color-primary;
            color: @color-light;
            border-top: none;
            padding-top: 7px;
          }

          &:before {
            content: "";
            display: block;
            color: #0699dc;
            margin-left: 0;
            top: 19.5px;
            background: #369ddb;
            position: absolute;
            width: 5px;
            border-radius: 50%;
            height: 5px;
          }
        }
      }
    }

    #menu-wrapper {
      display: none;

      .menu,
      .menu li.active {
        background: @color-primary;
      }
    }

    #main #left-main {
      padding: 0 15px;
      width: 100%;
      min-height: 0;

      &.sticky {
        position: relative;
      }

      .toc-hide-h3 {
        width: 100%;
      }
    }

    .menu-search button {
      height: 45px;
      background: @color-secondary;

      &:after {
        left: -14px;
        width: 14px;
        height: 43px;
        border: 0;
        border-width: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        -webkit-border-top-left-radius: 0;
        -webkit-border-bottom-left-radius: 0;
        -moz-border-radius-topleft: 0;
        -moz-border-radius-bottomleft: 0;
      }
    }
    /* show menu on search page */
    #vrtx-searchview #head-wrapper #menu-wrapper {
      /* edited in document ready javascript */
    }
    /* blockquote*/
    blockquote {
      &.half-width-left,
      &.half-width-right {
        width: 100%;
        float: none;
        margin-left: 0;
        margin-right: 0;
      }
    }

    #footer-wrapper {
      padding-bottom: 30px;
    }

    #footer-wrapper #footers {
      margin-bottom: 40px;
      padding: 0;
      background: transparent !important;

      ul.footer-links li {
        margin: 0 9px 5px 10px;
      }

      .col-2-5,
      .col-3-5 {
        width: 100%;
      }

      .libraries a {
        width: 50%;
        float: left;
        height: auto !important;
      }

      .col-3-5.vcard {
        padding: 20px 0;

        .libraries {
          a.xtrapadding {
            padding: 20px;
          }
        }
      }

      .col-2-5.responsible-login {
        bottom: 0;
        padding: 0 15px;
        position: relative;

        #footer-links,
        .content {
          display: block;
          float: none;
          margin-top: 40px;
        }
      }

      .content {
        margin-left: 0;
      }
    }
  }

  #scrolltop {
    -webkit-backface-visibility: hidden;
  }
}
/*------------------------------------*\
# colour
\*------------------------------------*/
// Primary, secondary etc...
@color-primary: #0b619d; //dark blue
@color-primary--dark: #0581bb; //darker blue
@color-primary--light: #009bdb; //lighter blue
@color-secondary: #c8cddd; //greyblue
@color-contrast: #ad061b; //burgunder (ring113-knapp)
// Neutral colors
@color-neutral--dark: #4f4f4f; //mørk grå
@color-neutral--air: #eceef5; //lys blågra
@color-neutral-light: #f5f7f8; // Suplementary colors
@color-light: #ffffff;
@color-dark: #101010; //Mork gra
@color-link: #006e9b;
/*------------------------------------*\
# fonts
\*------------------------------------*/
@font-serif: Georgia, "Roboto Slab", serif;
@font-menu: "Source Sans Pro", sans-serif;
@font-sans-serif: Helvetica, Arial, sans-serif;
